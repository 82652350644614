<template>
  <div id="page-user-list">
    
      <form method="POST" enctype="multipart/form-data"  @submit.prevent="submitForm">
       <div class="vx-row mb-6">

        <div class="vx-col w-full md:w-3/3">
          <div class="vx-card p-6 mb-6">
            <RoleForm></RoleForm>
          
          </div>
        </div>
        <div class="vx-col w-full md:w-3/3">
          <div class="vx-card p-6 mb-6">
            <div class="flex flex-wrap items-center justify-end">
              <vs-button button="submit" class="ml-auto" >Save Changes</vs-button>
            </div>
          </div>
        </div>
       </div>
      </form> 
	</div>
</template>

<script>
import vSelect from 'vue-select'
import RoleForm from './common/RoleForm';
import loaderMixin from '../../../mixins/loaderMixin';
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    vSelect,
    RoleForm
  },
  mixins: [loaderMixin],
  computed: {
    ...mapGetters({
        form: 'role/getFormObj'
    }),
    getRoleId() {
        return this.$route.params.roleId;
    },
  },
  created() {
    this.clearForm();
    this.clearValidationErrors();
     this.openLoading();
     this.fetchAndSetRoleById(this.getRoleId).then(() => {
       this.closeLoading();
     }).catch((r) => this.closeLoading());
  },
  methods: {
    ...mapActions({
      updateRoleAction: 'role/updateRoleAction',
      fetchAndSetRoleById: 'role/fetchAndSetRoleById',
      clearForm: 'role/clearForm',
      clearValidationErrors: 'validation/clearValidationErrors',
    }),
    submitForm() {
     this.openLoading();
      this.updateRoleAction({
        roleId: this.getRoleId,
        data: this.form
      }).then( async response => { 
        if(response.status === 202) {
          this.closeLoading();
          this.$vs.notify({ 
              text: 'Role updated successfully.',
              title: 'Role Updated',
              color: 'success',
              position: 'top-right'
          });
          this.$router.push('/roles');}
      }).catch(() => this.closeLoading());
    },
  },
}
</script>


<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
